"use client";
// ----------- 组件 ----------------- //
import JDIProductCardV2 from "@/common-components-src/js/productCard_V2/index";
import SafeLink from "@/components/SafeLink";
// ----------- 图标 ----------------- //
import ArrowRight from "@/assets/icons/ArrowRightBlack.svg";
import PopularIcon from "@/assets/icons/Popular.svg";
// ----------- scss ----------------- //
import style from "./style.module.scss";
import { goToCurrentRankPage } from "@/app/promo/best-seller/utils";

// -------------- Constants -------------------- //
const THREE_ITEMS = 3;
const SIX_ITEMS = 6;
const CONTAINER_SIZE_BIG = 1;
const BEST_SELLER_HREF = "/promo/best-seller";
// ----------------------------------------- //

import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25223";
interface BestSellerPropType {
  data: any;
}
export default function BestSellers({ data }: BestSellerPropType) {
  const { externalData, dataBatch } = data?.moduleData;
  const columnNumber = data?.columnNumber;
  // Check floor number to determine container length
  if (externalData.data?.length) {
    externalData.data.length = columnNumber === CONTAINER_SIZE_BIG ? SIX_ITEMS : THREE_ITEMS;
  }

  // ------------- Helper function -------------------- //
  const generateBestSellerTag = (text: string) => {
    return (
      <div className={style.bestSellerTag}>
        <PopularIcon />
        <span>{text}</span>
      </div>
    );
  };

  // -------------- Product Data -------------------- //
  const getProductData = (product: any, style: any, index: number) => {
    return {
      type: "1",
      skuId: product?.skuId,
      skuName: product?.categoryTabName,
      skuImg: product?.imageUrl,
      shouldShowCart: false,
      shouldShowMarketing: true,
      marketingArray: [generateBestSellerTag(product.saleQtyStr)],
      // ---- styles ------ //
      containerStyle: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        ...style.containerStyle,
      },
      skuNameStyle: {
        textAlign: "center",
      },

      skuImgStyle: {
        ...style.skuImgStyle,
      },
      priceStyle: {
        display: "none",
      },
      onClickProduct: () => goToCurrentRankPage(product.sequenceId, product.categoryTabName),
      trackingPointParams: genEventTrackingInfo(product, index),
    };
  };

  // -----------------埋点信息------------------------- //
  const genEventTrackingInfo = (product: any, index: number) => {
    const eventTrackingInfo = {} as { [key: string]: any };
    const json_param = { title: product.categoryTabName, third_category_name: "-100", second_category_name: "-100", first_category_name: "-100" };
    const expConfig = {
      useClick: false,
      stay_duration: 0,
      repeated: false,
      area_rate: 0.01,
    };
    eventTrackingInfo["productClickPoint"] = () => sendClickTrack.Home_Pro_BestSellers(json_param);
    eventTrackingInfo["productParam"] = json_param;
    eventTrackingInfo["productExpConfig"] = {
      event_id: EXPOSURE_RECORD.Home_Pro_BestSellers_Expo,
      config: expConfig,
      json_param: json_param,
    };
    return eventTrackingInfo;
  };

  // -------------- Container Styles -------------------- //
  const containerSizeSmall = {
    containerStyle: { maxWidth: "180px", maxHeight: "256px" },
    skuImgStyle: { width: "180px", height: "180px" },
  };
  const containerSizeLarge = {
    containerStyle: { maxWidth: "220px", maxHeight: "264px" },
    skuImgStyle: { width: "200px", height: "200px" },
  };
  const containerStyling = columnNumber === 1 ? containerSizeLarge : containerSizeSmall;
  // --------------------------------------------------- //

  return (
    <div className={style.container} style={{ width: columnNumber === 1 ? "100%" : "624px" }}>
      {/* Header */}
      <div
        className={style.header}
        onClick={() => sendClickTrack.Home_Pro_BestSellers_More()}
        exptag={"exp|" + EXPOSURE_RECORD.Home_Pro_BestSellers_More_Expo}
        data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
      >
        <p className={style.title}>{dataBatch?.config[0]?.title || "Best sellers"}</p>
        <div className={style.buttonContainer}>
          <SafeLink href={BEST_SELLER_HREF}>
            <div className={style.button}>
              <p className={style.land_text}>{dataBatch?.config[0]?.jumpButtonName || "View more"}</p>
              <ArrowRight className={style.arrow_right} color="#1A1A1A" />
            </div>
          </SafeLink>
        </div>
      </div>
      <div className={style.cardContainer}>
        <div className={style.contentContainer} style={{ height: columnNumber === 1 ? "264px" : "256px" }}>
          {externalData &&
            externalData.data?.map((item: any) => {
              return <JDIProductCardV2 key={item.categoryTabName} productData={getProductData(item, containerStyling)} />;
            })}
        </div>
      </div>
    </div>
  );
}
